<!-- loader.component.html -->
<!-- <div> -->
<div class="loader-container">
  <div *ngIf="!loginFailed" class="loader"></div>
  <div *ngIf="loginFailed" class="login-failed">
    <p style="color: red;">Oops! Something went wrong.</p>
    <p style="color: #555;">Please try again.</p>
    <button (click)="login()">Login</button>
  </div>
</div>

<!-- </div> -->