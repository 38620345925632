import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aca-expired-trainer',
  templateUrl: './expired-trainer.component.html',
  styleUrls: ['./expired-trainer.component.scss']
})
export class ExpiredTrainerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
