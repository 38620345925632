import { Injectable } from '@angular/core';
import { ApiCall } from '../../environments/env.api';
import { Observable } from 'rxjs';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GetAuthCodeurlService {
  apiURL: string = 'https://test.salesforce.com/services/oauth2/';
  constructor(private httpClient: HttpClient) { }

  public postAccessToken(access_token_1): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    console.log("Inside postAccessToken")
    let newApiURL;
    if (ApiCall.production) {
      newApiURL = 'https://a4fmzz1ij3.execute-api.us-east-1.amazonaws.com/prod/token-generatation';
    } else {
      newApiURL = 'https://g2atxp56r7vebt2etkz5jj5ate0kudde.lambda-url.us-east-1.on.aws/';
    }

    console.log(access_token_1, newApiURL, headers);
    let a = this.httpClient.get(newApiURL, {headers: headers});
    console.log("a : ", a);
    return a
  }
}
