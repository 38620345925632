import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
// import { BEARER } from '../../assets/bearer.json';
import { ApiCall } from '../../environments/env.api';
import { Observable } from 'rxjs';
import { checkAccessTokenExpiry } from './session-timout.service';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class TrainerConnectService {
  responseAuthToken;
  bearer;
  apiURL: string;
  baseUrl;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = localStorage.getItem('instance_url');
  }

  public getInitTraining(
    offset,
    courseFilter,
    trainerFilter,
    finalizedFilter,
    startDate,
    endDate,
    conId
  ): Observable<any> {
    if (ApiCall.production) {
      console.log('prod success');
      this.apiURL =
        'https://trainerconnectonline.my.salesforce.com/services/apexrest/TrainingsPage/';
    } else {
      this.apiURL = localStorage.getItem('instance_url') + '/services/apexrest/TrainingsPage/';
    }

    let apiCallInit =
      this.apiURL +
      'initializeTrainingsPage?offset=' +
      offset +
      '&courseFilter=' +
      courseFilter +
      '&finalizedFilter=' +
      finalizedFilter +
      '&trainerFilter=' +
      trainerFilter +
      '&startFilter=' +
      startDate +
      '&endFilter=' +
      endDate +
      '&conId=' +
      conId; //&courseFilter=Safety-Care Trainer Training (v6)&trainerFilter=Jason Roy&finalizedFilter=True
    // this.apiURL = this.apiURL + 'initializeTrainingsPage' + '?offset=' + offset;

    //https://trainerconnectonline--prosandbox.cs26.my.salesforce.com/services/apexrest/TrainingsPage/initializeTrainingsPage?offset=0&courseFilter=&finalizedFilter=&trainerFilter&startFilter=2020-01-01&endFilter=2020-03-31
    console.log('getInitTraining apiCall', apiCallInit);
    this.bearer = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.bearer
    });
    return this.httpClient.get(apiCallInit, { headers: headers }).pipe(catchError(checkAccessTokenExpiry));
  }

  public getactivity(
    traiFilt,
    startDateFilt,
    endDateFilt,
    conId
  ): Observable<any> {
    if (ApiCall.production) {
      console.log('prod success');
      this.apiURL =
        'https://trainerconnectonline.my.salesforce.com/services/apexrest/OtherActivityPage?target=initializeOtherActivityPage_search';
    } else {
      this.apiURL = localStorage.getItem('instance_url') + '/services/apexrest/OtherActivityPage?target=initializeOtherActivityPage_search';
    }
    let apiCallInit =
      this.apiURL +
      'initializeTrainingsPage?offset=' +
      // offset +
      '&trainerFilter=' +
      traiFilt +
      '&conId=' +
      conId +
      '&startFilter=' +
      startDateFilt +
      '&endFilter=' +
      endDateFilt;

    //&courseFilter=Safety-Care Trainer Training (v6)&trainerFilter=Jason Roy&finalizedFilter=True
    // this.apiURL = this.apiURL + 'initializeTrainingsPage' + '?offset=' + offset;

    //https://trainerconnectonline--prosandbox.cs26.my.salesforce.com/services/apexrest/TrainingsPage/initializeTrainingsPage?offset=0&courseFilter=&finalizedFilter=&trainerFilter&startFilter=2020-01-01&endFilter=2020-03-31
    console.log('getInitTraining apiCall', apiCallInit);
    this.bearer = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.bearer
    });
    return this.httpClient.get(apiCallInit, { headers: headers }).pipe(catchError(checkAccessTokenExpiry));
  }

  public postDeleteTraining(
    trainingId: string,
    conId: string
  ): Observable<any> {
    let apiCalldel;
    if (ApiCall.production) {
      apiCalldel =
        'https://trainerconnectonline.my.salesforce.com/services/apexrest/TrainingsPage/' +
        'deleteTraining';
    } else {
      apiCalldel = localStorage.getItem('instance_url') + '/services/apexrest/TrainingsPage/' +
        'deleteTraining';
    }
    this.bearer = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.bearer
    });
    const trainingIdToDel = trainingId;
    console.log('trainingIdToDel' + trainingIdToDel);

    let bodyp = {
      trainingId: trainingIdToDel,
      conId: conId
    };
    return this.httpClient
      .post(apiCalldel, bodyp, { headers: headers })
      .pipe(catchError(checkAccessTokenExpiry))
      .map(res => res);
  }
}
