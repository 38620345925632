import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
// import { awsAdminConfig } from '../../../environments/env.api';
import { Router } from '@angular/router';
import { AdminContactService } from './../../services/admin-contact.service';
import * as AWS from 'aws-sdk';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OtherServiceService } from '../../services/other-service.service';

@Component({
  selector: 'aca-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {
  emailVal;
  adminContactId;
  adminContactDetails = [];
  response;
  response1;

  adminSearch = new FormGroup({
    email: new FormControl('')
  });
  constructor(
    public _router: Router,
    private formBuilder: FormBuilder,
    public _acs: AdminContactService,
    private snackBar: MatSnackBar,
    private others: OtherServiceService
  ) { }

  onSubmit() {
    const t = this;
    this.getUserContact1(this.adminContactDetails).then(value => {

      console.log("value is:", value);
      const resp: any = value;
      console.log("contact id is: ", value[0].val)
      localStorage.setItem("conn_id", value[0].val)
      console.log(resp);
      if (resp.length > 0) {
        const name = resp[2].val + ' ' + resp[3].val + ' (Admin)';
        console.log(name);
        t._acs.setContactId(resp[0].val, name, resp[1].val);

        console.log('value is set! ' + this.adminContactId);

        t._router.navigate(['/trainer-connect']);
      } else {
        this.openSnackBar('User not Found!', 'Dismiss');
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['error-snackbar']
    });
  }


  getUserContact1(adminContactDetails) {
    return new Promise(resolve => {
      setTimeout(() => {
        const Username = this.adminSearch.value.email
          ? this.adminSearch.value.email
          : '';
        console.log("Email val is: ", Username)
        const param = {
          "Username": Username
        }
        this.others.getDetails(param).subscribe(resp => {
          console.log("Resp from getuser contact is: ", resp)
          this.response1 = resp;
          this.response = this.response1.UserAttributes;
          for (let i = 0; i < this.response.length; i++) {
            if (this.response[i].Name === 'custom:TCContactId') {
              console.log('admin 3');

              adminContactDetails.push({
                val: this.response[i].Value
              });
            }
            if (this.response[i].Name === 'custom:TCContactAccount') {
              adminContactDetails.push({
                val: this.response[i].Value
              });
            }
            if (this.response[i].Name === 'given_name') {
              adminContactDetails.push({
                val: this.response[i].Value
              });
            }
            if (this.response[i].Name === 'family_name') {
              adminContactDetails.push({
                val: this.response[i].Value
              });
            }
          }
          resolve(adminContactDetails);
        }, error => {
          console.log("Error")
        })
      }, 3000);
    })
  }

  // getUserContact(adminContactDetails) {
  //   console.log('get user contact ');
  //   return new Promise(resolve => {
  //     setTimeout(() => {
  //       const csp = new AWS.CognitoIdentityServiceProvider();

  //       const emailVal = this.adminSearch.value.email
  //         ? this.adminSearch.value.email
  //         : '';
  //       const upoolId = awsAdminConfig.userpoolId;
  //       const params = {
  //         UserPoolId: upoolId /* required */,
  //         Username: emailVal /* required */
  //       };
  //       console.log(emailVal);

  //       csp.adminGetUser(params, function (err, data) {
  //         console.log('admin 1');
  //         if (err) {
  //           console.log(err, err.stack);
  //         } // an error occurred
  //         else {
  //           console.log(data);
  //           const response = data.UserAttributes;

  //           for (let i = 0; i < response.length; i++) {
  //             if (response[i].Name === 'custom:TCContactId') {
  //               console.log('admin 3');

  //               adminContactDetails.push({
  //                 val: response[i].Value
  //               });
  //             }
  //             if (response[i].Name === 'custom:TCContactAccount') {
  //               adminContactDetails.push({
  //                 val: response[i].Value
  //               });
  //             }
  //             if (response[i].Name === 'given_name') {
  //               adminContactDetails.push({
  //                 val: response[i].Value
  //               });
  //             }
  //             if (response[i].Name === 'family_name') {
  //               adminContactDetails.push({
  //                 val: response[i].Value
  //               });
  //             }
  //           }
  //         }
  //         resolve(adminContactDetails);
  //       });
  //     }, 3000);
  //   });
  // }

  ngOnInit() {

    this.adminSearch = this.formBuilder.group({
      email: ''
    });
  }
}
