/*!
 * @license
 * Alfresco Example Content Application
 *
 * Copyright (C) 2005 - 2020 Alfresco Software Limited
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail.  Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with Alfresco. If not, see <http://www.gnu.org/licenses/>.
 */

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, RouteReuseStrategy } from '@angular/router';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule
} from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { APP_ROUTES } from './app-routing.module';
import { AppLoginModule } from './components/login/login.module';
import { environment } from '../environments/environment';
import { AuthGuard } from '../app/auth/auth.guard';
import { AuthService } from '../app/services/auth.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ExpiredTrainerComponent } from './components/expired-trainer/expired-trainer.component';
import Amplify, { Auth } from 'aws-amplify';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio'
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MaterialModule } from './material.module';
import { AdminHomeComponent } from './components/admin-home/admin-home.component';
import { SalesforceCallbackComponent } from './components/salesforce-login/salesforce-callback.component';
import { SalesforceLoginComponent } from './components/salesforce-login/salesforce-login.component';
import { CommonModule } from '@angular/common';
//import { PaymentRedirectGuard } from './payment-redirect.guard';
//import { TcHeaderComponent } from './components/tc-header/tc-header.component';
// import { AmplifyAngularModule, AmplifyService, AmplifyModules } from 'aws-amplify-angular';
// import Auth from '@aws-amplify/auth';
// import Interactions from '@aws-amplify/interactions';
// import Storage from '@aws-amplify/storage';
// Amplify.configure({
//   Auth: {
//     mandatorySignIn: true,
//     region: 'us-east-1',
//     userPoolId: 'us-east-1_CWbhVUguO',
//     userPoolWebClientId: '2p0pv8idkkpa1cek5hc5heooro',
//     authenticationFlowType: 'USER_PASSWORD_AUTH'
//   }
// });
@NgModule({
  imports: [
    BrowserModule,
    //  ScrollingModule, CdkVirtualScrollViewport,
    environment.e2e ? NoopAnimationsModule : BrowserAnimationsModule,
    RouterModule.forRoot(APP_ROUTES, {
      useHash: true,
      enableTracing: false
    }),
    // AppCurrentUserModule,
    // AppHeaderModule,
    MaterialModule,
    MatRadioModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    ScrollingModule,
    AppLoginModule,
    HttpClientModule
  ],
  declarations: [AppComponent,
    AdminHomeComponent,
    ExpiredTrainerComponent,
    SalesforceCallbackComponent,
    SalesforceLoginComponent],

  providers: [
    AuthService,
    AuthGuard,
    //    PaymentRedirectGuard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // { provide: AppConfigService, useClass: null },
    // {
    //   provide: TRANSLATION_PROVIDER,
    //   multi: true,
    //   useValue: {
    //     name: 'app',
    //     source: 'assets'
    //   }
    // }
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
  exports: [
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    ScrollingModule,
    AppLoginModule,
    HttpClientModule,
    MatIconModule,
    MatFormFieldModule,
    MaterialModule,
    MatRadioModule
  ]
})
export class AppModule { }
