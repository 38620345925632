import { HttpErrorResponse } from '@angular/common/http';

export function checkAccessTokenExpiry(error: HttpErrorResponse): any {
  console.log('checkAccessTokenExpiry is called');
  console.log('error.status ', error.status);
  console.log('error ', error);
  if (error.status === 0 || error.status === 401 || error.statusText === 'Unauthorized' || error.statusText === 'Unknown Error') {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/';
  } else {
    throw error;
  }
}
