import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OnInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { checkAccessTokenExpiry } from '../../services/session-timout.service';

@Component({
  selector: 'app-salesforce-callback',
  templateUrl: './salesforce-login.component.html',
  styleUrls: ['./salesforce-login.component.css']
})
export class SalesforceCallbackComponent implements OnInit {

  loginFailed: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private httpClient: HttpClient) {
    this.loginFailed = false;
  }

  ngOnInit() {
    console.log("call back component called");
    this.handleSalesforceCallback();
  }

  login() {
    this.router.navigate(['/']);
  }

  async handleSalesforceCallback() {

    console.log("handle callback called");

    const fragment = this.route.snapshot.fragment;
    console.log(fragment);
    if (fragment) {
      console.log('Callback component Fragment:', fragment);
      // Parse the fragment to extract parameters
      const params = new URLSearchParams(fragment);

      // Access the access_token parameter
      const accessToken = params.get('access_token');
      const instanceUrl = params.get('instance_url');
      const issuedAt = params.get('issued_at');

      // Now you can use the accessToken value as needed
      console.log('Access Token:', accessToken);
      console.log("Instance url: ", instanceUrl);
      console.log("Issued at: ", issuedAt);

      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('instance_url', instanceUrl);
      localStorage.setItem('issued_at', issuedAt);

      await this.retrieveAndStoreUserInfo(accessToken, instanceUrl);

      // Navigate to '/trainer-connect'
      // this.router.navigate(['/trainer-connect']);
    } else {
      console.log('Callback component No fragment found');
      this.loginFailed = true;
    }
  }

  async retrieveAndStoreUserInfo(accessToken, instanceUrl) {

    let apiUrl = instanceUrl + '/services/apexrest/HomePageLightning';

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken
    });

    try {
      // Use toPromise() to convert the observable to a promise
      const response = await this.httpClient.get(apiUrl, { headers: headers }).pipe(catchError(checkAccessTokenExpiry)).toPromise();

      // Access the values in the response body
      console.log('Response Body:', response);
      //   {
      //     "lastName": null,
      //     "isAdmin": null,
      //     "firstName": null,
      //     "emailId": "devteam+qbs@fantailtech.com",
      //     "contactId": null
      // }
      localStorage.setItem("firstname", response["firstName"]);
      localStorage.setItem("lastname", response["lastName"]);
      localStorage.setItem("email", response["emailId"]);
      localStorage.setItem("conn_id", response["contactId"]);
      localStorage.setItem("isadmin", response["isAdmin"]);
      if (!response["contactId"]) {
        localStorage.clear();
        this.loginFailed = true;
        return;
      }
      // return response; // You can return the response or extract specific values as needed
      this.router.navigate(['/trainer-connect']);
    } catch (error) {
      console.error('Error retrieving user info:', error);
      // throw error; // Handle the error as needed
      localStorage.clear();
      this.loginFailed = true;
    }

  }

}
