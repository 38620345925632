<div class="admin-home-container">
  <div class="user-guide-instruction">
    Please enter the exact email of the Trainer that you would like to login as:
  </div>
  <div class="fan__tc_body" [formGroup]="adminSearch" (ngSubmit)="onSubmit()">
    <div class="fan__gl_l50">
      <div style="width: 96%;float: left">
        <mat-form-field class="fan__gl_c">
          <mat-label>Email</mat-label>
          <input
            matInput
            placeholder="Search Email here..."
            formControlName="email"
          />
        </mat-form-field>
      </div>
    </div>
    <button
      class="fan__gl_btn-2"
      mat-flat-button
      color="primary"
      type="submit"
      (click)="onSubmit()"
    >
      <mat-icon style="transform: scale(0.8);transform-origin: top left;"
        >search</mat-icon
      >Search
    </button>
  </div>
</div>
