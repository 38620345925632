import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
//import { of } from 'rxjs/observable/of';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    // return this.auth.isAuthenticated().pipe(
    //   tap(loggedIn => {
    //     if (!loggedIn) {
    //       this.router.navigate(['/']);
    //     }
    //   })
    // );

    const accessToken = localStorage.getItem('access_token');

    if (accessToken) {
      // Access token is present, allow access to the route
      return of(true);
    } else {
      // Access token is not present, redirect to the home page
      this.router.navigate(['/']);
      return of(false);
    }

  }
}
