import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiCall } from '../../environments/env.api';
import { Observable } from 'rxjs';
import { checkAccessTokenExpiry } from './session-timout.service';
import { catchError } from 'rxjs/operators';
// import { BEARER } from '../../assets/bearer.json';
//import { ApiCall } from '../../environments/env.api';

@Injectable({
  providedIn: 'root'
})
export class HomePageService {
  responseAuthToken;
  bearer;
  apiURL: string;

  constructor(private httpClient: HttpClient) {

  }
  public postinitializeHomePage(contactId): Observable<any> {
    this.bearer = localStorage.getItem('access_token');

    if (ApiCall.production) {
      this.apiURL =
        'https://trainerconnectonline.my.salesforce.com/services/apexrest/HomePageLightning?target=initializeHomePage';
    } else {
      this.apiURL = localStorage.getItem('instance_url') + '/services/apexrest/HomePageLightning?target=initializeHomePage';
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.bearer,
      'Access-Control-Allow-Origin': '*'
    });
    console.log("bearer is", this.bearer);
    let bodyp = {
      conId: contactId
    };
    return this.httpClient
      .post(this.apiURL, bodyp, { headers: headers })
      .pipe(catchError(checkAccessTokenExpiry))
      .map(res => res);
  }
  public postinitializedashboard(contactId, email, Isadmin): Observable<any> {
    this.bearer = localStorage.getItem('access_token');

    console.log("Inside postinitializedashboard");
    if (ApiCall.production) {
      this.apiURL =
        'https://trainerconnectonline.my.salesforce.com/services/apexrest/HomePageLightning?target=initializeHomePage';
    } else {
      this.apiURL = localStorage.getItem('instance_url') + '/services/apexrest/HomePageLightning?target=initializeHomePage';
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.bearer,
      'Access-Control-Allow-Origin': '*'
    });

    console.log("this.bearer : ", this.bearer);
    let bodyp = {
      conId: contactId,
      email: email,
      Isadmin: Isadmin
    };
    console.log('body in home', bodyp);
    return this.httpClient
      .post(this.apiURL, bodyp, { headers: headers })
      .pipe(catchError(checkAccessTokenExpiry))
      .map(res => res);
  }

  public getCertDetails(contactId, type): Observable<any> {
    this.bearer = localStorage.getItem('access_token');

    if (ApiCall.production) {
      this.apiURL =
        'https://trainerconnectonline.my.salesforce.com/services/apexrest/HomePageLightning?target=getCertDetails';
    } else {
      this.apiURL = localStorage.getItem('instance_url') + '/services/apexrest/HomePageLightning?target=getCertDetails';
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.bearer,
      'Access-Control-Allow-Origin': '*'
    });
    console.log("bearer is", this.bearer);
    let bodyp = {
      conId: contactId,
      certType: type
    };
    return this.httpClient
      .post(this.apiURL, bodyp, { headers: headers })
      .pipe(catchError(checkAccessTokenExpiry))
      .map(res => res);
  }
}
