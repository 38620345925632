<!-- <adf-login
  [copyrightText]="'application.copyright' | adfAppConfig | translate"
  providers="ECM"
  successRoute="/home"
  logoImageUrl="./assets/images/alfresco-logo.svg"
  [showRememberMe]="false"
  [showLoginActions]="false"
>
</adf-login> -->


<div class="fan__gl_c">
  <cdk-virtual-scroll-viewport itemSize="1000" class="viewport_login">
    <mat-progress-bar mode="query" color="#6250b4" *ngIf="loading"></mat-progress-bar>
    <div class="fan__tc_hc">

      <div class="fan__gl_mid">

        <div class="fan__gl_c">
          <div class="cognitodiv">

            <div (click)="gotoLoginScreen()" class="header" style="padding-top: 2em 0em; cursor: pointer;">
              <div class="logo">
                <img src="../../../assets/images/QBS-Logo-Primary.png" />
              </div>
              <div class="title">
                <h1>Trainer Connect</h1>
              </div>
            </div>
            <p class="fan__gl_c" style="text-align: center;font-weight: 400;font-size: 14px;margin-bottom: 0px;"></p>

            <div id="signindiv" class="loginbox" [formGroup]="logingroup">

              <div class="fan__gl_c embossedbox">
                <div class="width_85">
                  <p class="signin">Sign in to your account</p>
                  <div class="fan__gl_c">
                    <label class="label-div">Username*</label>
                    <input class="username" style="border-color: lightgrey;" formControlName="username"
                      placeholder="Username" required />
                  </div>
                  <div class="fan__gl_c">
                    <label class="label-div">Password*</label>
                    <input class="username" style="border-color: lightgrey; margin-bottom: 2px;" type="password"
                      formControlName="password" required placeholder="Enter your password" />
                    <span class="forget-password"> Forgot Password?
                      <a (click)="reset()" class="reset-password">Reset password</a>
                    </span>
                  </div>
                  <div class="btn-div">
                    <button id="siginbutton" [disabled]="dis" class="signin-btn" style="background: #6250b4;"
                      type="submit" (click)="onSubmit()">
                      Sign In
                    </button>
                  </div>
                </div>


              </div>

            </div>

            <div id="resetdiv" class="loginbox" style="width: 360px; margin: 0 auto; display: none;"
              [formGroup]="resetgroup">

              <div class="fan__gl_c embossedbox">
                <div class="width_85">
                  <p class="signin">Reset your password</p>
                  <div class="fan__gl_c">
                    <div class="codetext">
                      You will receive a verification code
                    </div>
                  </div>
                  <div class="fan__gl_c">
                    <label class="label-div">Username*</label>
                    <input class="username" style="border-color: lightgrey;" placeholder="Username"
                      formControlName="username" required />
                  </div>

                  <div class="btn-div-reset">
                    <div class="reset-div">
                      <button class="signin-btn" style="background-color: FF9900;" (click)="resetpasswordProces()"
                        type="submit">
                        Submit
                      </button>
                    </div>
                    <div class="reset-div" style="float: left;">
                      <div style="margin-top: 1em; color: #828282;">
                        <a style="cursor: pointer; color: #FF9900;" (click)="BackToSignin()">
                          Back to Sign in
                        </a>
                      </div>

                    </div>
                  </div>
                </div>


              </div>

            </div>

            <div id="confirmationdiv" class="loginbox" style="width: 360px; margin: 0 auto; display: none;"
              [formGroup]="confirmcodegroup">

              <div class="fan__gl_c embossedbox">
                <div class="width_85">
                  <p class="signin">Reset your password</p>
                  <div class="fan__gl_c">
                    <div class="codetext">
                      Enter the code you received and set a new password
                    </div>
                  </div>
                  <div class="fan__gl_c" style="margin-top: 1em;">
                    <label class="label-div">Confirmation Code *</label>
                    <input class="username" style="border-color: lightgrey; margin-top: 0.6em;" placeholder="Enter code"
                      formControlName="code" required />
                  </div>
                  <div class="fan__gl_c">
                    <label class="label-div">New Password *</label>
                    <input class="username" style="border-color: lightgrey; margin-top: 0.6em;" placeholder="Password"
                      formControlName="newpassword" required />
                  </div>
                  <div class="btn-div-reset">
                    <div class="reset-div">
                      <button class="signin-btn" style="background-color: FF9900;" (click)="submitcode()" type="submit">
                        Verify
                      </button>
                    </div>
                    <div class="reset-div" style="float: left;">
                      <div style="margin-top: 1em; color: #828282;">
                        <a style="cursor: pointer; color: #FF9900;" (click)="coderesend()">
                          Resend Code
                        </a>
                      </div>

                    </div>
                  </div>
                </div>


              </div>

            </div>
            <div id="changepassword" class="loginbox" style="width: 360px; margin: 0 auto; display: none;"
              [formGroup]="changepasswordgroup">

              <div class="fan__gl_c embossedbox">
                <div class="width_85">
                  <p class="signin">You are required to update your password</p>
                  <div class="fan__gl_c">
                    <label class="label-div">Password*</label>
                    <input class="username" style="border-color: lightgrey;" placeholder="Password" type="password"
                      formControlName="tempPassword" required />
                  </div>

                  <div class="btn-div-reset">
                    <div class="reset-div">
                      <button class="signin-btn" style="background-color: FF9900;" (click)="updatePassword()"
                        type="submit">
                        Submit
                      </button>
                    </div>
                    <div class="reset-div" style="float: left;">
                      <div style="margin-top: 1em; color: #828282;">
                        <a style="cursor: pointer; color: #FF9900;" (click)="BackToSignin()">
                          Back to Sign in
                        </a>
                      </div>

                    </div>
                  </div>
                </div>


              </div>

            </div>
            <div id="errordiv" class="errordiv">

              <div class="fan__gl_c" style="padding: 1em;display: flex;">
                <span class="alerticon">⚠</span>
                <div class="alertmessage">{{this.errormessage}}</div>
                <div>
                  <a class="alert-close" (click)="closeerror()">×</a>
                </div>
              </div>

            </div>



            <!-- <div class="fan__gl_c" style="font-weight: 600">
              <div style="width:100%; text-align: center;padding-bottom: 0.3em; font-weight: normal; color: #ff0033">
                *Please enter a valid username.
              </div>
            </div> -->
            <!-- <amplify-authenticator
              [hide]="['SignUp']"
              :authConfig="configurationOptions"
              style="overflow-y: scroll;"
            >
            </amplify-authenticator> -->
            <!-- <div class="fan__gl_c" style="justify-content: center;">
              <div class="google-btn" style="margin-left: 31%;margin-top: 1em;">
                <div class="google-icon-wrapper">
                  <img class="google-icon" style="cursor: pointer;" (click)="signInWithGoogle()"
                    src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" />
                </div>
                <p class="btn-text" style="cursor: pointer;" (click)="signInWithGoogle()"><b>Sign in with google</b></p>
              </div>
            </div> -->

            <p class="fan__gl_c" style="text-align: center;font-weight: 400;font-size: 14px; margin-top:4em">
              Please email us at connect@qbs.com or call (855) QBS-MAIN
              [855-727-6246] if you need assistance
            </p>
          </div>
        </div>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
