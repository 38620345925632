import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
// import { BEARER } from '../../assets/bearer.json';
//import { map } from 'rxjs/operators';
import { ApiCall } from '../../environments/env.api';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { checkAccessTokenExpiry } from './session-timout.service';

@Injectable({
  providedIn: 'root'
})
export class OtherServiceService {
  bearer;
  baseUrl;

  constructor(private httpClient: HttpClient) {
    this.bearer = localStorage.getItem('access_token');
    this.baseUrl = localStorage.getItem('instance_url');

  }

  public delteactivity(id, contactid): Observable<any> {
    let bodyp = {
      trainingId: id,
      conId: contactid
    };
    console.log(bodyp);
    let postInitNewTrainingsapiURL: string;
    if (ApiCall.production) {
      postInitNewTrainingsapiURL =
        'https://trainerconnectonline.my.salesforce.com/services/apexrest/OtherActivityPage/?target=deleteOtherActivity';
    } else {
      postInitNewTrainingsapiURL = this.baseUrl + '/services/apexrest/OtherActivityPage/?target=deleteOtherActivity';
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.bearer
    });

    return this.httpClient
      .post(postInitNewTrainingsapiURL, bodyp, { headers: headers })
      .pipe(catchError(checkAccessTokenExpiry))
      .map(res => res);
  }
  public getAllOtherTrainingList(conId): Observable<any> {
    let postInitNewTrainingsapiURL: string;
    if (ApiCall.production) {
      postInitNewTrainingsapiURL =
        'https://trainerconnectonline.my.salesforce.com/services/apexrest/OtherActivityPage/?target=initializeOtherActivityPage';
    } else {
      postInitNewTrainingsapiURL = this.baseUrl + '/services/apexrest/OtherActivityPage/?target=initializeOtherActivityPage';
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.bearer
    });

    return this.httpClient
      .post(postInitNewTrainingsapiURL, conId, { headers: headers })
      .pipe(catchError(checkAccessTokenExpiry))
      .map(res => res);
  }

  public getOtherTrainingDetail(bodyp): Observable<any> {
    let postInitNewTrainingsapiURL: string;
    if (ApiCall.production) {
      postInitNewTrainingsapiURL =
        'https://trainerconnectonline.my.salesforce.com/services/apexrest/OtherActivityDetails/?target=initializePage';
    } else {
      postInitNewTrainingsapiURL = this.baseUrl + '/services/apexrest/OtherActivityDetails/?target=initializePage';
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.bearer
    });

    return this.httpClient
      .post(postInitNewTrainingsapiURL, bodyp, { headers: headers })
      .pipe(catchError(checkAccessTokenExpiry))
      .map(res => res);
  }

  public postInitNewTrainings(mode, params): Observable<any> {
    let postInitNewTrainingsapiURL: string;
    if (mode == 'edit') {
      if (ApiCall.production) {
        postInitNewTrainingsapiURL =
          'https://trainerconnectonline.my.salesforce.com/services/apexrest/OtherActivity/?target=initializeEditOtherActivity';
      } else {
        postInitNewTrainingsapiURL = this.baseUrl + '/services/apexrest/OtherActivity/?target=initializeEditOtherActivity';
      }
    } else {
      if (ApiCall.production) {
        postInitNewTrainingsapiURL =
          'https://trainerconnectonline.my.salesforce.com/services/apexrest/OtherActivity/?target=initializeOtherActivityPage';
      } else {
        postInitNewTrainingsapiURL = this.baseUrl + '/services/apexrest/OtherActivity/?target=initializeOtherActivityPage';
      }
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.bearer
    });

    return this.httpClient
      .post(postInitNewTrainingsapiURL, params, { headers: headers })
      .pipe(catchError(checkAccessTokenExpiry))
      .map(res => res);
  }

  public saveNewTraining(mode, params): Observable<any> {
    let postInitNewTrainingsapiURL: string;
    if (ApiCall.production) {
      postInitNewTrainingsapiURL =
        'https://trainerconnectonline.my.salesforce.com/services/apexrest/OtherActivity/?target=saveOtherActivity';
    } else {
      postInitNewTrainingsapiURL = this.baseUrl + '/services/apexrest/OtherActivity/?target=saveOtherActivity';
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.bearer
    });

    return this.httpClient
      .post(postInitNewTrainingsapiURL, params, { headers: headers })
      .pipe(catchError(checkAccessTokenExpiry))
      .map(res => res);
  }

  public getDetails(param) {
    let url = ''

    var token = "non";
    var arrayOfKeys = Object.keys(localStorage);
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.indexOf("CognitoIdentityServiceProvider") > -1 && key.indexOf("idToken") > -1) {
        token = localStorage.getItem(key);
      }
    }

    if (ApiCall.production) {
      url = "https://a4fmzz1ij3.execute-api.us-east-1.amazonaws.com/prod/admin-home";
    } else {
      url = "https://8untdoorq4.execute-api.us-east-1.amazonaws.com/stage/admin-home";
    }
    const headers = new HttpHeaders({
      'Authorization': token
    });
    console.log("Params in http: ", param)
    return this.httpClient
      .post(url, param, { headers: headers })
      .map(resp => resp);
  }
}
