

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Amplify from 'aws-amplify';
//import awsconfig from './aws-exports';

Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_CWbhVUguO',
    google_client_id:
      '1004053377307-pfgbivj5m4mcll6mmnacd8fsqi58gbjv.apps.googleusercontent.com',
    userPoolWebClientId: '2p0pv8idkkpa1cek5hc5heooro'
  }
});

import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic() //it creates the browser environment
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
