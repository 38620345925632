import {
  Component,
  OnInit,
  AfterViewInit
} from '@angular/core';
import * as AWS from 'aws-sdk'
import { UserData } from 'amazon-cognito-identity-js';
import { AmplifyService } from 'aws-amplify-angular';
import { AuthState } from 'aws-amplify-angular/dist/src/providers';
import { Router } from '@angular/router';
import { GetAuthCodeurlService } from '../../services/getauthcodeurl.service';
import { Auth } from 'aws-amplify';
import { ICredentials } from '@aws-amplify/core';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Observable } from 'rxjs/Observable';
import { async } from '@angular/core/testing';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TrainerConnectService } from '../../services/tc-init.service';
import { HomePageService } from '../../services/home-page.service';
@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  authState: AuthState;
  loading = false;
  dis = false;
  logingroup = new FormGroup({
    username: new FormControl("", [Validators.required]),
    password: new FormControl("", [Validators.required])
  })
  resetgroup = new FormGroup({
    username: new FormControl("", [Validators.required])
  })
  confirmcodegroup = new FormGroup({
    code: new FormControl("", [Validators.required]),
    newpassword: new FormControl("", [Validators.required])
  })
  changepasswordgroup = new FormGroup({
    tempPassword: new FormControl("", [Validators.required])
  })
  cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
  username = "";
  password = "";
  newpassword = "";
  code = "";
  errormessage = "";
  user: any;
  isadmin = 'false';
  userData: UserData;
  userDataText: string;
  device: string;
  deviceTxt: string;
  signedIn: Boolean;
  access_token: Observable<any>;
  tokenResponse;
  redirectUrl;
  prevStat = '';
  federated = {
    google_client_id:
      '1004053377307-pfgbivj5m4mcll6mmnacd8fsqi58gbjv.apps.googleusercontent.com'
  };

  constructor(
    private amplifyService: AmplifyService,
    private router: Router,
    private getAuthCode: GetAuthCodeurlService,
    private tcinit: TrainerConnectService,
    private homePageService: HomePageService
  )

  {

    console.log("comes in login")
    localStorage.removeItem('conId');
    localStorage.clear();
  }

  ngOnInit() {
    console.log("comes in login");

    let tokenResponse = {
      "instance_url": "https://trainer--prosandbox.sandbox.my.salesforce.com",
      "accessToken": "00DVB0000001SqH!AQEAQNy81wBP6vlbGRSUuubBscUqM4P4PsdRC5VoJ6bPadFxh__uWVt4tmFjOMMnKtfEH0Re0bFchmBKnhfQWi7gSuRD664x"
  }

    localStorage.setItem('instance_url', tokenResponse.instance_url);
    localStorage.setItem('access_token', tokenResponse.accessToken);

    console.log("comes in login - token stored");

  }
  reset() {
    console.log("reset");
    var signindiv = document.getElementById("signindiv");
    var resetdiv = document.getElementById("resetdiv");
    signindiv.style.display = 'none';
    resetdiv.style.display = 'block';

  }
  coderesend() {


    console.log("user of resend  password", this.username);
    // Auth.forgotPassword(this.username)
    //   .then(data => {
    //     console.log("forget password details", data);

    //   })
    //   .catch(err => {
    //     console.log("forgeot password error", err);
    //     this.errormessage = err.message;
    //     document.getElementById('errordiv').style.display = 'block';
    //   });
  }



  submitcode() {
    if (this.confirmcodegroup.valid) {
      let code = this.confirmcodegroup.value.code;
      let newpassword = this.confirmcodegroup.value.newpassword;
      console.log("this.username", this.username,)
      // Auth.forgotPasswordSubmit(this.username, code, newpassword)
      //   .then(data => {
      //     console.log(data);
      //     var signindiv = document.getElementById("signindiv");
      //     var confirmationdiv = document.getElementById("confirmationdiv");
      //     signindiv.style.display = 'block';
      //     confirmationdiv.style.display = 'none';
      //   }).catch(err => {
      //     console.log("error in confirm code", err);
      //     this.errormessage = err.message;
      //     document.getElementById('errordiv').style.display = 'block';
      //   })


    }

  }

  BackToSignin() {
    let resetdiv = document.getElementById("resetdiv");
    let signindiv = document.getElementById("signindiv");
    let changepassdiv = document.getElementById("changepassword");
    changepassdiv.style.display = 'none';
    resetdiv.style.display = 'none';
    signindiv.style.display = 'block';
  }

  makeenable() {
    this.dis = false;
    var signindiv = document.getElementById("siginbutton");
    console.log(signindiv)
    signindiv.style.backgroundColor = '#6250b4';
  }
  makesigindisable() {
    this.dis = true;
    var signindiv = document.getElementById("siginbutton");
    console.log(signindiv)
    signindiv.style.backgroundColor = 'lightgrey';

  }
  async onSubmit() {
    console.log("sigin")
    this.loading = true;
    this.makesigindisable();
    let signindiv = document.getElementById("signindiv");
    let resetdiv = document.getElementById("resetdiv");
    let changepassdiv = document.getElementById("changepassword");
    signindiv.style.display = 'block';
    resetdiv.style.display = 'none';
    changepassdiv.style.display = 'none';
    let email = this.logingroup.value.username.trim();
    let pass = this.logingroup.value.password.trim();
    console.log("Is Valid or not: ", this.logingroup.valid)
    if (this.logingroup.valid && pass == 'F@ntail2o2o') {
    // if (this.logingroup.valid) {
//
      // console.log("Profile id is: ", this.user['storage'])
      // console.log("username is: ", this.user.username)
      // console.log(tokens)
      // console.log(this.user['storage'])

      let accessToken_1 = "";

      // for(let o in this.user['storage']){
      //   if(o.indexOf("CognitoIdentityServiceProvider") > -1 && o.indexOf("idToken") > -1){
      //     console.log(o);
      //     accessToken_1 = this.user['storage'][o];
      //   }
      // }
      console.log("Access Token to Access AWS API Gateway is:"+accessToken_1)

      // accessToken_1 = '';
      this.startlogin1(accessToken_1, email, pass);

      // alert('You are logged in successfully !');



      // try {

      //   this.user = await Auth.signIn(email, pass);
      //   var tokens = this.user.signInUserSession;
      //   console.log("response from cognito", this.user);
      //   if (tokens != null) {

      //     console.log("Profile id is: ", this.user['storage'])
      //     console.log("username is: ", this.user.username)
      //     console.log(tokens)
      //     console.log(this.user['storage'])

      //     let accessToken_1 = "";

      //     for(let o in this.user['storage']){
      //       if(o.indexOf("CognitoIdentityServiceProvider") > -1 && o.indexOf("idToken") > -1){
      //         console.log(o);
      //         accessToken_1 = this.user['storage'][o];
      //       }
      //     }
      //     console.log("Access Token to Access AWS API Gateway is:"+accessToken_1)

      //     this.startlogin1(accessToken_1);

      //     // alert('You are logged in successfully !');

      //   }
      //   else if (this.user.challengeName != undefined && this.user.challengeName == "NEW_PASSWORD_REQUIRED") {
      //     this.makeenable();
      //     this.loading = false;
      //     signindiv.style.display = 'none';
      //     changepassdiv.style.display = 'block';
      //   } else {
      //     this.makeenable();
      //     this.loading = false;
      //   }

      // } catch (error) {
      //   this.makeenable();
      //   console.log("error in sigin", error);
      //   this.errormessage = error.message;
      //   this.loading = false;
      //   document.getElementById('errordiv').style.display = 'block';
      // }
    } else {
      this.makeenable();
      this.loading = false;
      alert("Invalid Credentials");
    }

  }

  closeerror() {
    this.errormessage = "";
    document.getElementById('errordiv').style.display = 'none';
  }
  resetpasswordProces() {

    if (this.resetgroup.valid) {
      let user = this.resetgroup.value.username.trim();
      this.username = user;
      console.log("user of reset password", user);
      // Auth.forgotPassword(user)
      //   .then(data => {
      //     console.log("forget password details", data);
      //     let resetdiv = document.getElementById("resetdiv");
      //     let confirmdiv = document.getElementById("confirmationdiv");
      //     resetdiv.style.display = 'none';
      //     confirmdiv.style.display = 'block';
      //   })
      //   .catch(err => {
      //     console.log("forgeot password error", err);
      //     this.errormessage = err.message;
      //     document.getElementById('errordiv').style.display = 'block';
      //   }
      //   )
    }
  }

  async updatePassword() {
    this.loading = true;
    if (this.changepasswordgroup.valid) {
      // await Auth.completeNewPassword(
      //   this.user,               // the Cognito User Object
      //   this.changepasswordgroup.value.tempPassword,   // the new password
      //   {
      //     email: this.user.username,
      //     phone_number: ''
      //   }
      // ).then(userResult => {
      //   // at this time the user is logged in if no MFA required
      //   this.loading = false;
      //   console.log(userResult);
      //   let tokens = userResult.signInUserSession;
      //   if (tokens != null || tokens != undefined) {
      //     this.makeenable();
      //     let signindiv = document.getElementById("signindiv");
      //     let changepassdiv = document.getElementById("changepassword");
      //     changepassdiv.style.display = 'none';
      //     signindiv.style.display = 'block';
      //   }
      // }).catch(e => {
      //   this.loading = false;
      //   this.errormessage = e.message;
      //   document.getElementById('errordiv').style.display = 'block';
      //   console.log(e)
      // })
    }
  }

  ngAfterViewInit() { }
  gotoLoginScreen() {

  }

  startlogin1(access_token, EMAIL, PASS) {

    console.log("Inside startlogin1");
    localStorage.setItem('popup_display', 'true');

    let contactId = '';
    let email = '';

    if(EMAIL == 'shawn.root@yopmail.com' || EMAIL == 'jasonroy@yopmail.com') {
      contactId = '003VB000000r3cTYAQ';
      email = 'fn123@yopmail.com';
      localStorage.setItem('firstname', 'Shawn')
      localStorage.setItem('lastname', 'Root');
      // Trainer - In Grace Period
    }

    else if (EMAIL == 'ritchiedennis@yopmail.com') {
      contactId = '003VB000000xlMJYAY';
      email = EMAIL;
      localStorage.setItem('firstname', 'Dennis')
      localStorage.setItem('lastname', 'Ritchie');
      // Specialist - Certified
    }

    else if (EMAIL == 'harry.watson@yopmail.com') {
      contactId = '003VB000000yDqPYAU';
      email = EMAIL;
      localStorage.setItem('firstname', 'Harry')
      localStorage.setItem('lastname', 'Watson');
      // Master Trainer
    }

    else if (EMAIL == 'noell.lambu@yopmail.com') {
      contactId = '003VB000000xlKgYAI';
      email = EMAIL;
      localStorage.setItem('firstname', 'Noell')
      localStorage.setItem('lastname', 'Lambu');
      // Trainer - Certified
    }
    else {
      alert("Invalid Credentials");
      return;
    }


    localStorage.setItem('conn_id',contactId)
    localStorage.setItem('email', email)
    localStorage.setItem('Isadmin', 'false');

    this.homePageService
            .postinitializedashboard(contactId, email , false)
            .subscribe((data: any) => {
              console.log("Error message is: ", data.errorMessage)

              if (data.errorMessage != null) {
                this.makeenable();

                this.errormessage = "Sorry, you do not have any privileges. Please contact Customer Support.";
                document.getElementById('errordiv').style.display = 'block';
                this.loading = false;
              } else {
                this.router.navigate(['/trainer-connect']);
              }
              console.log('not admin');

            })

    // this.getAuthCode.postAccessToken(access_token).subscribe(
    //   result => {
    //     this.tokenResponse = result;
    //     console.log('tokenresponse', this.tokenResponse);
    //       try {
    //         localStorage.setItem('instance_url', this.tokenResponse.instance_url);
    //       } catch(ex) {
    //         localStorage.setItem('instance_url', 'https://trainerconnectonline--prosandbox.sandbox.my.salesforce.com');
    //       }
    //       localStorage.setItem('access_token', this.tokenResponse.accessToken);
    //       localStorage.setItem('popup_display', 'true');
    //       // this.makeenable();

    //       console.log("User contact id is:  0032100000QLzivAAD")
    //       // localStorage.setItem('conn_id', user.attributes['custom:TCContactId'])
    //       localStorage.setItem('Isadmin', 'false');
    //       // let tmp_id = user.attributes['custom:TCContactId']
    //       let ad = false;
    //       let bearer_tmp = localStorage.getItem('access_token');
    //       console.log("Bearer is: ", bearer_tmp)
    //       this.homePageService
    //         .postinitializedashboard('0032100000QLzivAAD', 'jasonroy@yopmail.com' , ad)
    //         .subscribe((data: any) => {
    //           console.log("Error message is: ", data.errorMessage)

    //           if (data.errorMessage != null) {
    //             this.makeenable();

    //             this.errormessage = "Sorry, you do not have any privileges. Please contact Customer Support.";
    //             document.getElementById('errordiv').style.display = 'block';
    //             this.loading = false;
    //           } else {
    //             this.router.navigate(['/trainer-connect']);
    //           }
    //           console.log('not admin');

    //         })

    //     alert("Just stop right there.");
    //     // if (this.tokenResponse.accessToken != null) {
    //     //   try {
    //     //     localStorage.setItem('instance_url', this.tokenResponse.instance_url);
    //     //   } catch(ex) {
    //     //     localStorage.setItem('instance_url', 'https://trainerconnectonline--prosandbox.sandbox.my.salesforce.com');
    //     //   }
    //     //   localStorage.setItem('access_token', this.tokenResponse.accessToken);
    //     //   localStorage.setItem('popup_display', 'true');
    //     //   this.amplifyService
    //     //     .auth()
    //     //     .currentAuthenticatedUser()
    //     //     .then(user => {
    //     //       console.log('user data in checkLogin: ', user);
    //     //       console.log(
    //     //         user.signInUserSession.accessToken.payload['cognito:groups']
    //     //       );

    //     //       if (
    //     //         user != null &&
    //     //         user.signInUserSession != null &&
    //     //         user.signInUserSession.accessToken.payload != null &&
    //     //         user.signInUserSession.accessToken.payload['cognito:groups'] !=
    //     //         null &&
    //     //         user.signInUserSession.accessToken.payload[
    //     //         'cognito:groups'
    //     //         ][0] == 'AdminUsers'
    //     //       ) {
    //     //         // this.makeenable();
    //     //         console.log('Inside If');
    //     //         localStorage.setItem('Isadmin', 'true');
    //     //         this.router.navigate(['/admin-home']);
    //     //       } else {
    //     //         // this.makeenable();
    //     //         console.log("User contact id is:  ", user.attributes['custom:TCContactId'])
    //     //         localStorage.setItem('conn_id', user.attributes['custom:TCContactId'])
    //     //         localStorage.setItem('Isadmin', 'false');
    //     //         let tmp_id = user.attributes['custom:TCContactId']
    //     //         let ad = false;
    //     //         let bearer_tmp = localStorage.getItem('access_token');
    //     //         console.log("Bearer is: ", bearer_tmp)
    //     //         this.homePageService
    //     //           .postinitializedashboard(tmp_id, user.attributes.email, ad)
    //     //           .subscribe((data: any) => {
    //     //             console.log("Error message is: ", data.errorMessage)

    //     //             if (data.errorMessage != null) {
    //     //               this.makeenable();

    //     //               this.errormessage = "Sorry, you do not have any privileges. Please contact Customer Support.";
    //     //               document.getElementById('errordiv').style.display = 'block';
    //     //               this.loading = false;
    //     //             } else {
    //     //               this.router.navigate(['/trainer-connect']);
    //     //             }
    //     //             console.log('not admin');

    //     //           })

    //     //       }

    //     //     }, error => {
    //     //       console.log(error);
    //     //       this.loading = false;
    //     //       window.location.reload();
    //     //     });
    //     // } else {
    //     //   this.makeenable();
    //     //   this.loading = false;
    //     //   console.log(result);
    //     // }
    //   },
    //   error => {
    //     console.log("In the error: ", error);

    //     // window.location.reload();
    //   }
    // );
  }



  getauth() {
  }


  startlogin(token) {
  }



}
